<template>
  <!-- Listing Item -->
    <div class="properties" @click="goToDevelopment(item.folio, item.developmentNameEs)">
      <div v-if="info.logoClean" class="watermark" :style="'background-image:url('+info.logoClean+'); '"> </div>
      <a
        @click="goToDevelopment(item.folio, item.developmentNameEs)"
        :style="'background-image: url(' + item.image.smallphoto + ');'"
        class="img  d-flex justify-content-center align-items-center"
      >
        <div class="icon d-flex justify-content-center align-items-center">
          <span class="icon-search2"></span>
        </div>
      </a>

      <div class="text p-3">
        <span class="status status rent"># {{ item.folio }}</span>

        <div class="d-flex">
          <div class="one">
            <h3>
              <a
                @click="goToDevelopment(item.folio, item.developmentNameEs)"
                >{{ item.developmentNameEs }}</a
              >
            </h3>
            <!--<p>VIVIENDA</p>-->
          </div>
        </div>

        <div class="d-flex">
          <div class="two">
            <span class="price" v-if="item.priceFrom != 0"
              >{{ item.priceFrom }}
            </span>
          </div>
        </div>
        <p>
          <i class="fa fa-map-marker-alt"></i>{{ item.colony }},
          {{ item.city }}
        </p>
        <b id="leyendaPrecios">*PRECIOS SUJETOS A CAMBIOS SIN PREVIO AVISO</b>
        <hr />
        <p
          class="bottom-area d-flex"
          v-if="
            item.bedFrom != 0 ||
              item.m2cFrom != 0 ||
              item.m2cTo != 0
          "
        >
          <span v-if="item.bedFrom > 0"
            ><i class="flaticon-bed"></i> {{ item.bedFrom }}</span
          >
          <span class="ml-auto" 
            ></span
          >
          <span v-if="item.m2cFrom != 0"
            ><i class="flaticon-selection"></i> {{ item.m2cFrom }} m
            <sup>2</sup>c</span
          >
          <span v-else-if="item.m2cTo != 0"
            ><i class="flaticon-selection"
              ></i
            >   {{ item.m2cTo }} m <sup>2</sup>c</span
          >

        </p>
        <p
          class="bottom-area d-flex m-3"
          v-else
        >
        </p>
    </div>
  </div>
  <!-- Listing Item / End -->
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      info:'_getInfoCompany',
    }),
  },
  methods: {
    goToDevelopment() {
      let nameDevFilt = services.normalize(this.item.developmentNameEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Development",
        params: {
          folio,
          nameDevFilt,
        },
      });
    },
  },
};
</script>

<style scoped>
.listing-content {
  height: 250px;
}

.imageProp img {
  width: 100%;
}
#leyendaPrecios {
  font-size: 9px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}



.watermark{
    height: 250px;
    width: 92%;
    background-size: 15%;
    z-index: 1;
    cursor: pointer;
}

.watermark{
  opacity: .6;
}
</style>
