<template>
  <main>
    <!-- Content
    ==================================================-->
    <section class="ftco-section bg-light">
      <div class="container mt-5">
        <div v-if="!agents" class="text-center">
          <Preloader v-show="isVisible" />
          <NotResults v-show="noVis" code="208"/>
        </div>
        <div v-else class="row mb-5">
          <AgentCard
            v-for="(item, i) in agents"
            class="col-md-6 col-lg-4"
            :key="item.folio"
            :name="item.folio"
            :index="i"
            :agent="item"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import AgentCard from "@/components/layout/AgentCard.vue";
import NotResults from '@/components/layout/NotFound';
export default {
  components: {
    Banner,
    Preloader,
    AgentCard,
    NotResults
  },
  data() {
    return {
        isVisible: true,
        noVis: false,
    };
  },
  mounted() {
    this.ShowLoaderSecs();
  },
  methods: {
    ShowLoaderSecs() {
      setTimeout(() => {
        this.isVisible = false;
        this.noVis=true;
      }, 1200);
      
    },
  },
  computed:{
        ...mapGetters({
            agents:'_getTeam',
            error:'_getError',
        }),
    },
    created(){
      this.$store.dispatch('getTeam');
    },
};
</script>