<template>
  <main>

    <!--/ Development Grid Star /-->

    <section class="ftco-section bg-light">
      <div class="container">
        <Preloader v-if="!developments" />
        <div class="row" v-else>
          <div class="col-md-4"
          v-for="(item, i) in developments"
          :key="item.folio"
          :name="item.folio"
          :index="i">
            <DevelopmentCard 
            :item="item" />
          </div>
        </div>
      </div>
    </section>
    <!--/ Development Grid End /-->
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/layout/Preloader.vue";
import Banner from "@/components/layout/Banner.vue";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
import SearchForm from "@/components/search/SearchForm.vue";

export default {
  components: {
    Banner,
    DevelopmentCard,
    Preloader,
    SearchForm,
  },
  computed: {
    ...mapGetters({
      developments: "_getDevelopments",
    }),
  },
  created() {
    this.$store.dispatch("getDevelopments");
  },
};
</script>
