<template>
  <div>

    <section class="ftco-section contact-section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-7 mb-5">
            <ContactForm />
          </div>

          <div class="col-lg-5">
            <div class="pl-4 pb-4 pr-4 mb-3">
              <div class="bg-white card-contact p-3 mb-3">
                <div class="text-center">
                  <img
                    v-if="info.logo"
                    class="logo mt-3 mb-0"
                    :src="info.logo"
                    alt="logo_web"
                  />
                </div>
                <ul class="contact-info">
                  <li v-if="info.address">
                    <div class="info">
                      <i class="icon icon-map-marker"></i>
                      <p class="p-contact">
                        {{ info.address }}
                      </p>
                    </div>
                  </li>
                  <li v-if="info.reserveemail">
                    <div class="info">
                      <i class="icon icon-envelope"></i>
                      <p class="p-contact">
                        <a :href="'mailto:' + info.reserveemail">{{
                          info.reserveemail
                        }}</a>
                      </p>
                    </div>
                  </li>
                  <li v-if="info.phone ">
                    <div class="info">
                      <i class="icon icon-phone"></i>
                      <p class="p-contact">
                        <a
                          class="contact-links"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Contáctenos"
                          :href="'tel:+52' + info.phone"
                          target="_blank"
                        >
                          {{ info.phone }}
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from "@/components/layout/ContactForm.vue";
import Banner from "@/components/layout/Banner.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Banner,
    SearchForm,
    ContactForm,
  },
  data() {
    return {
      contact: true,
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.logo {
  display: inline-block;
  max-width: 150px;
  margin-top: auto;
}

.logo img {
  margin-top: 40%;
  margin-left: -10px;
  max-width: 20px;
  max-height: 30px;
}

.color-grey{
  color: #808080;
}
.contact-section .contact-info{
  list-style: none;
  padding: 0;
  margin: 0;
}
.contact-section ul li{
  list-style: none;
  font-weight: 300;
}
.contact-section .info{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.contact-section .info i{
  font-size: 1.6rem;
  color:#128ac8;
}
.contact-section .info p{
  margin: 0px;
  margin-left: 0px;
  margin-left: 1.5rem;
  font-weight: 300;
  color:#666;
}
.contact-section .info a{
    color:#666;
}
.contact-section .card-contact{
  border-radius: 6px;
  box-shadow: 0px 0px 2px #e7e7e7 !important;
}
.contact-section form{
    border-radius: 6px;
  box-shadow: 0px 0px 2px #e7e7e7 !important;
}
</style>