<template>
  <div v-if="agent" class="mb-4 ftco-animate agent-card">
    <div class="card">
      <img  @click="goToAgent()" class="card-img-top" :src="agent.image ? agent.image : '/images/avatar.png'"  :alt="`${agent.fullname}-img`">
      <div class="card-body pb-0 pt-0">
        <h5  @click="goToAgent()" class="card-title"><b>{{ agent.fullname }}</b></h5>
        <p v-if="agent.postname != 0" class="card-text">{{agent.postname}}</p>
        <p v-else class="card-text text-white"> .</p>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <a
          v-if="agent.cellphone"
          class="position mb-1 text-dark"
          :href="'tel:' + agent.cellphone"
          target="_blank"
          ><i class="fas fa-phone"></i>  {{ agent.cellphone }}</a
        >
        </li>
        <li class="list-group-item">
          <a
          v-if="agent.email"
          class="position mb-1 text-dark"
          :href="'mailto:' + agent.email"
          target="_blank"
          ><i class="fas fa-envelope"></i>  {{ agent.email }}</a
        >
        </li>
      </ul>
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <button
            v-if="nameRoute!='Agent'"
            @click="goToAgent()" type="submit" class="btn btn-primary "> {{ $t("components.agent_card.link") }}
          </button>
          <div v-if="agent.tweet || agent.fb || agent.gmas || agent.instagram || agent.youtube|| agent.skypeid || agent.linkedin" class=" text-center">
          <ul class="ftco-footer-social list-unstyled mb-0 ml-2 ">
          <li   v-if="agent.tweet" class="ftco-animate ">
            <a :href="agent.tweet" target="_blank"
              ><span class=" icon-twitter1 "></span
            ></a>
          </li>
          <li   v-if="agent.fb" class="ftco-animate ">
            <a :href="agent.fb" target="_blank"
              ><span class="icon-facebook-f"></span
            ></a>
          </li>
          <li   v-if="agent.gmas" class="ftco-animate">
            <a :href="agent.gmas" target="_blank"
              ><span class="icon-google-plus"></span
            ></a>
          </li>
          <li  v-if="agent.instagram"  class="ftco-animate">
            <a :href="agent.instagram" target="_blank"
              ><span class="icon-instagram"></span
            ></a>
          </li>
          <li v-if="agent.youtube"  class="ftco-animate">
            <a :href="agent.youtube" target="_blank"
              ><span class="icon-youtube-play"></span
            ></a>
          </li>
          <li   v-if="agent.skypeid" class="ftco-animate">
            <a :href="agent.skypeid" target="_blank"
              ><span class="icon-skype"></span
            ></a>
          </li>
          <li  v-if="agent.linkedin"  class="ftco-animate">
            <a :href="agent.linkedin" target="_blank"
              ><span class="icon-linkedin1"></span
            ></a>
          </li>
        </ul>
          </div>
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToAgent() {
      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>


<style scoped>
.staff img{
    object-fit: contain;
    object-position: center;
    width: 100%;
}
p,a{
    overflow-wrap: break-word;
}
a{
    cursor: pointer;
}
.agent-card .card{
  box-shadow: 0px 0px 2px #e7e7e7 !important;
  border: 1px solid #eaeff5;
  border-radius: 1rem;
}
.agent-card .list-group-flush .list-group-item {
  border: none;
  padding-bottom: 0px;
  font-size: 13px;
}
.agent-card i{
  color:#128ac8;
}
.agent-card .card-footer{
  background-color: #fff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.agent-card .ftco-footer-social li{
  margin: 0 3px 0 0;
}
.agent-card .ftco-footer-social li a {
  height: 25px;
  width: 25px;
  color: #fff;
  background-color: #cdcbcb;
}
.agent-card .ftco-footer-social li a span{
  font-size: 13px;
}
.agent-card .ftco-footer-social li a:hover {
  height: 27px;
  width: 27px;
  color: #fff;
  background: #128ac8;
}
.agent-card .card-img-top {
  padding: 1rem;
  height: 34vh;
  object-fit: contain;
}
.agent-card .btn-primary{
  border-radius: .5rem;
}
</style>