<template>
  <div v-if="agent" class="mb-5 agent-details">
    <Banner :pageTitle="agent.fullname" image="/images/hero.jpg"/>
    <div class="site-section site-section-sm agent-section">
      <div class="container">
        <div class="row">
          <!-- Search
          ==================================================-->
          <SearchForm />
        </div>
      </div>
    </div>
      <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <!--img :src="agent.image" alt="Image" class="img-fluid rounded mb-4"-->
          <AgentCard :agent="agent"/>
          <ContactForm/>
        </div>
        <div class="col-lg-8">
          <div class="row mb-5 text-center">
            <div v-if="agent.slogan" class="w-100 ">
                <h1 class="my-4 property-offer-type rounded"> "{{agent.slogan}}" </h1>
            </div>
            <template v-if="!properties || error == 204" class="text-center">
              <Preloader v-show="isVisible" class="w-100"/>
              <NotResults v-show="noVis" code="208" class="w-100"/>
            </template>
            <template v-else>
              <PropertyCard
                v-for="(item, i) in properties"
                :key="item.folio"
                :name="item.folio"
                :index="i"
                :item="item"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from "@/components/layout/Banner.vue";
import Preloader from '@/components/layout/Preloader.vue';
import PropertyCard from "@/components/properties/PropertyCard.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import AgentCard from "@/components/layout/AgentCard.vue";
import NotResults from "@/components/layout/NotFound";
export default {
  components: {
    Banner,
    Preloader,
    PropertyCard,
    ContactForm,
    SearchForm,
    AgentCard,
    NotResults
  },
  data(){
      return {
          agentId:'',
          isVisible: true,
          noVis: false,
      }
  },
  mounted() {
    this.ShowLoaderSecs();
  },
  methods: {
    ShowLoaderSecs() {
      setTimeout(() => {
        this.isVisible = false;
        this.noVis=true;
      }, 1200);
    },
  },
  computed:{
    ...mapGetters({
        agent:'_getDataAgent',
        properties: "_getPropertiesByAgent",
        error: "_getError",
    }),
    totalProps(){
        return this.properties.length
    }
},
/*  computed:{
      agentData(){
          return this.$store.getters._getDataAgent()
      }
  },*/
  created(){
      let agentId=this.$route.params.folio;
      this.$store.dispatch("getAgentInfo", agentId);
      this.$store.dispatch("getPropertiesByAgent", agentId);
  }
};
</script>
<style scoped>
  .agent-section{
    background-color:#262d42;
  }
.agent-details .bg-white{
  box-shadow: 0px 0px 2px #e7e7e7 !important;
  border: 1px solid #eaeff5;
  border-radius: 1rem;
  }
  .agent-details .bg-white .btn{
    width: 100% !important;
    display: block!important;;
  }
</style>